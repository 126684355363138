<template>
  <div class="form-contain col-xs-12 col-md-10 col-md-offset-1">
    <div class="form-header px-1"
      :class="ar ? 'text-right' : ''"
    >
      Sign Up
    </div>
    <div class="form-subheader px-1"
      :class="ar ? 'text-right' : ''"
    >
      Please Fill In The Fields Carefully !
    </div>
    <form >
      <div class="form-group col-xs-12 col-md-6">
        <label class="input-label">
          Full Name
        </label>
        <input  
          type="text"  
          class="form-control text-input" 
          placeholder="Username">
      </div>
      <div class="form-group col-xs-12 col-md-6">
        <label class="input-label">
          E-mail Address
        </label>
        <input 
          type="email"  
          class="form-control text-input" 
          placeholder="Email Address">
      </div>
      <div class="form-group col-xs-12 col-md-6">
        <label class="input-label">
          Password
        </label>
        <input type="password"  
          class="form-control text-input" 
          placeholder="Password">
      </div>
      <div class="form-group col-xs-12 col-md-6">
        <label class="input-label">
          Confirm Password
        </label>
        <input type="password"  
          class="form-control text-input" 
          placeholder="Confirm Password">
      </div>
      <div class=" text-center ">
        <button 
          type="submit"  
          class="btn-pri-lg my-4 signUp-btn" 
        >
          Sign Up
        </button>
      </div>

       <div class="row text-center">
          <div class="col-lg-12 ">
            <div class=" form-subheader">
              Already Have An Account
              <a class="form-subheader strong text-underline pointer" @click='goLogin'>Sign In</a>
            </div>
          </div>
        </div>
    </form>
  </div>
</template>

<script>
export default {
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  },
  methods: {
    goLogin() {
      this.$router.push({
        name: 'Login'
      })
    }
  }
}
</script>