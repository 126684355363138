import Axios from 'axios';
const http = function(method = 'get', url = '', params = {})
{
    if(!params) params = {};    
    if(method == 'get' && params)
    {
        if(url.split("?").length == 1) url += '?';
        url += $.param(params);
        params = null;
    }

    let headers = {
        'Accept': 'application/json',
    };
    return Axios[method]((!url || url.substr(0, 4).toLowerCase() != 'http' ? '/' : '')+url, params, {headers})
                .then(response => {                    
                    return response ? response.data : {error: true};
                }).catch(error => {
                    return error.response;
                });
}

export default {
    get(url, params) {
        return http("get", url, params);
    },
    post(url, params)
    {
        return http("post", url, params);
    }
}