<template>
  <div class="form-contain signIn col-xs-12 col-md-8 col-lg-6"
    :class="ar ? ['col-md-offset-3', 'col-lg-offset-4'] : ['col-md-offset-2', 'col-lg-offset-3']"
  >
    <div class="form-header"
      :class="ar ? 'text-right' : ''"
    >
      Sign In
    </div>
    <div class="form-subheader"
      :class="ar ? 'text-right' : ''"
    >
      Please Fill In THe Form Fields
    </div>
    <form>
      <div class="form-group">
        <label class="input-label">
          E-mail Address
        </label>
        <input 
          type="text" 
          class="form-control text-input" 
          placeholder="E-mail-address"
        >
      </div>
      <div class="form-group">
        <label class="input-label">
          Password
        </label>
        <input 
          type="password" 
          class="form-control text-input" 
          placeholder="Password">
      </div>
        <div class="row my-4">
          <div class="col-lg-12">
            <div class="text-underline"
              :class="ar ? 'text-right' : ''"
            >
              <a class="login-forgot-password pointer ">Forgot Password?</a>
            </div>
          </div>
        </div>
      <div class="form-group">
        <button class="btn-pri-lg btn-block">
          Sign In
        </button>
      </div>
      <div class="form-group">
         <div class="row text-center">
          <div class="col-lg-12 ">
            <div class=" form-subheader">
              Don't have an account 
              <a class="form-subheader strong text-underline pointer" @click='goSignup'>Sign Up</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  },
  methods: {
    goSignup() {
      this.$router.push({
        name: 'Register'
      })
    }
  }
}
</script>
