<template>
  <div class="container-fluid" v-if='item.business && !modalLoading'>
    <div class="row" >
      <div class="col-12 col-md-4" :class="t_ar">
        <div class="order-image" >
          <img :src='item.business.media[0].url' />
        </div>
      </div>
      <div class="col-12 col-md-8 pa-2">
        <div class=" opt-name mb-2"> {{item.business.name }}</div>
        <div class=" opt-label"> {{$t('orderNumber')}} <span :class="f_ar">{{item.order_number}}</span></div>
        <div class=" opt-label" :class="isCanceled(item.id) ? 'danger' : ''"> {{item.status }}</div>
        <div class=" opt-label mt-2"> {{item.date }}  ( {{item.time}} )</div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col-12 " :class="t_ar">
        <div class="opt-name mb-2">{{$t('clientOptions')}}</div>
        <div class="opt-label mb-2"> {{$t('shippingMethod')}} <span class="mx-2" :class="f_ar_reverse">{{item.shipping_method}}</span></div>
        <div class="opt-label" v-if='item.payment != null'> {{$t('paymentMethod')}} <span class="mx-2" :class="f_ar_reverse">{{payText(item.payment.method)}}</span></div>
        <div class="opt-label" v-if='item.payment != null'> {{$t('paymentStatus')}} <span class="mx-2" :class="f_ar_reverse">{{item.payment.status}}</span></div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row px-1">
      <div class="opt-name">
        {{$t('orderItems')}}
      </div>
      <div class="col-12 mt-2" v-for="(ord, index) in item.items" :key='index' :class="t_ar">
        <div class="main-card">
          <div class="opt-label">
            {{ord.name}}   x  {{ord.count}}
            <span :class="f_ar_reverse">  € {{ord.price}} </span>
          </div>
          <div class="px-1 mt-2">
            <div class="data-sub" v-for='(opt, index) in ord.product_options' :key='index'>
              - {{opt.label}} <span :class="f_ar_reverse"> € {{opt.price}}</span> 
            </div>  
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col-12" :class="t_ar">
        <div class="card-sec">
          <div class="pro-header mb-4">
              {{$t('summary')}}
             </div>
             <div class="opt-label">{{$t('subTotal')}} <span :class="f_ar_reverse"> {{item.subtotal}} €</span></div>
             <div class="opt-label">{{$t('VAT')}} <span :class="f_ar_reverse"> {{item.tax}} €</span></div>
             <div class="opt-label">{{$t('delivery')}} <span :class="f_ar_reverse">{{item.delivery || 0}} €</span></div>
             <!-- <div class="opt-label">{{$t('discount')}} <span :class="f_ar_reverse">{{item.discount || 0}} €</span></div> -->
             
             <div class="opt-name text-center total-card mt-4">{{$t('total')}} € {{item.total}}</div>
        </div>
      </div>
    </div>

    <div class="row mt-3 px-2 justify-content-around">
        <button 
          :disabled='loading'
          v-if="!isCanceled(item.id) && (item.payment.status.toLowerCase() == 'waiting for client' && payText(item.payment.method).toLowerCase() == 'paypal')"
          class="btn-pri col-5 mt-2"
          @click='goPay'
        >
          {{$t('PaypalPay')}} 
          <i class="fa fa-paypal" :class="f_ar"></i>
        </button>
        <button 
          :disabled='loading'
          v-if="!isCanceled(item.id) && (item.status.toLowerCase() == 'pending' || item.status.toLowerCase() == 'order received')"
          class="btn-error col-5 mt-2"
          @click='cancelOrder(item.id)'
        >{{$t('cancelOrder')}}</button>
    </div>
  </div>
</template>

<script>
import md5 from 'md5';

export default {
  props: ['item', 'cancelOrder', 'isCanceled'],

  methods: {
    payText(text) {
      if (text == 1) {
        return 'Cash On Delivery'
      } else if (text == 3) {
        return 'Payment On Delivery'
      }else if (text == 2) {
        return 'Paypal'
      } 
    },
    goPay() {
      $('#orderModal').modal('hide');
      const created = this.item.created_at,
          id = this.item.id,
          pay = this.item.payment.id,
          bus = this.item.business.id;

      const updateSecret = this.makeSecret(created, id, pay, bus);

      console.log(this.item.subtotal)
      this.$router.push({
        name: 'Payment',

        params: {
          client_id: this.item.business.paypal_client_id,
          client_secret: this.item.business.paypal_secret,
          updateSecret: updateSecret,
          order_id: id,
          payment_id: pay,
          total: this.item.total,

          subTotalWithNoTax: this.item.subtotal - this.item.tax,
          VAT: this.item.tax,
          subTotal: this.item.subtotal,
          delivery_price: this.item.delivery,
          couponValue: this.item.disctount
        }
      })
    },
    makeSecret(created, id, pay, bus) {
      return md5(`${created}|${id}|${pay}|${bus}`);
    },
  },

  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    modalLoading() {
      return this.$store.getters.modalLoading;
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  }
}
</script>