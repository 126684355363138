<template>
<div class="container-fluid" :class="'dirs-'+$t('direction')" >
    <section class="row d-flex justify-content-center text-center" v-if="!$root.reservation || $root.reservation !== 'auto-reservation'">
        <div class="my-5 px-1 col-lg-8 col-12 col-md-10 panel panel-default">
           
            <div id="AttendeesInfo"> 
                <div class="pa-4 card-container">

                    <!-- Messages -->
                    <div class="" v-if="success">
                        <div class="alert alert-success ">
                            <i class="fa fa-check-circle"></i>
                            <span v-if="selectedBusiness.reservation">
                                {{$t('bookedSuccess')}}
                                <span v-if="selectedBusiness.tickets.numbers && selectedBusiness.tickets.numbers[selectedBusiness.tickets.numbers.length - 1] > 0">
                                    {{$t('ticketsNumers', {numbers: selectedBusiness.tickets.ticket_number ? Object.values(selectedBusiness.tickets.ticket_number).join(",") : selectedBusiness.tickets.numbers.join(", ")}) }}
                                </span>
                            </span>
                            <span v-else>{{$t('checkedInSuccess')}}</span>
                        </div>

                        <div class="panel panel-success">
                            <div class="panel-heading" v-if="selectedBusiness.reservation && selectedBusiness.tickets.code">{{'Your Tickets'}}</div>
                            <div class="panel-body">
                                <div class=" text-center" v-if="selectedBusiness.reservation && selectedBusiness.tickets.code">
                                    <img class="" src="/assets/images/loading.gif" v-if="!selectedBusiness.tickets.loaded" alt="">
                                    <div class="row justify-content-center" v-else>
                                        <div class="col-6">
                                            <button :disabled="printStatus && print" class="btn  btn-lg btn-block btn-success" @click="printing()">
                                                <i :class="printStatus ? 'fa-spin fa-spinner' : 'fa-print'" class="fa" style="height: auto !important;"></i>
                                                {{$t('print')}}
                                            </button>
                                            <button :disabled="printStatus && print" class="btn btn-lg btn-block btn-success" @click="pdf()">
                                                <i :class="printStatus ? 'fa-spin fa-spinner' : 'fa-file-pdf'" class="fa" style="height: auto !important;"></i>
                                                {{$t('pdf')}}
                                            </button>
                                        </div>
                                    </div>
                                    <iframe 
                                        v-show="selectedBusiness.tickets.loaded"
                                        @load="selectedBusiness.tickets.loaded = true;$forceUpdate()"
                                        class="w-100" style="height: 550px"
                                        :src="$root.$domain+'businesses/booking/scan/'+selectedBusiness.tickets.id+'/'+selectedBusiness.tickets.code" 
                                        frameborder="0"></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- Business list -->
                    <div class="" v-if="!form.business_id && !success">
                        <!-- <hr /> -->
                        <!-- <hr /> -->
                        <h2 class="mb-4 text-center bot-border">
                            {{$t('ChoosePlace')}}
                        </h2>

                        <div class=" ">
                            <div class="row" v-if="!loading">
                                <div class="col-12">
                                    <!-- <label class="input-label d-none d-xl-block d-md-block d-lg-block">
                                        {{$t('PlaceName')}}
                                    </label> -->
                                    <input :disabled="loading" type="search" class="text-input-bot form-control" :placeholder="$t('SearchName')" 
                                    @keydown.enter="findMatch()"
                                    v-model="filter.text" />
                                    <!-- <input type="search" class="form-control" :placeholder="$t('SearchName')" 
                                        v-model="filter.search" @keyup="findMatch()" @click="findMatch()"  @blur="findMatch()" /> -->
                                </div>
                                
                                <div class="col-sm-12 col-md-6">
                                        <!-- <label class="input-label d-none d-xl-block d-md-block d-lg-block">
                                            {{$t('PlaceCategory')}}
                                        </label> -->
                                        <select :disabled="loading" class="form-control select-input-bot" v-model="filter.category_id" @change="findMatch">
                                            <option class="text-muted" :value="undefined">{{$t('PlaceCategory')}}</option>
                                            <option v-for="(cat,id,i) in categories" :key="i" :value="id"
                                                v-html="cat.name"></option>
                                        </select>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                        <!-- <label class="input-label d-none d-xl-block d-md-block d-lg-block">
                                            {{$t('PlaceCity')}}
                                        </label> -->
                                        <select :disabled="loading" class="form-control select-input-bot" v-model="filter.city_id" @change="findMatch">
                                            <option class="text-muted" :value="undefined">{{$t('PlaceCity')}}</option>
                                            <option v-for="(cat,id,i) in cities" :key="i" :value="id">
                                                {{cat.name}}
                                            </option>
                                        </select>
                                </div>
                                <div class="col-sm-8 text-left p-4">
                                    <label>
                                        <input :disabled="loading" type="checkbox" class="inlineBlock" 
                                        @change="findMatch()"
                                        v-model="filter.booking_only" />
                                        &nbsp;
                                        {{$t('availableBookingOnly')}}
                                    </label>                                    
                                </div>
                                <div class="col-sm-4 p-4">
                                    <button type="button" class="btn btn-pri" @click="findMatch()">
                                        <i class="fa fa-search"></i>
                                        {{'search'|t}}
                                    </button>
                                </div>
                            </div>
                                
                            <div v-if="(!loading || (loading && filter.page > 1)) && business && Object.keys(business).length > 0 " 
                                style="max-height: 500px;overflow-y: auto;" class="pb-5" v-scrolling>
                                <div class="list-group mt-6">
                                    <a class="list-group-item pointer " 
                                    v-for="(rs,id,i) in business" :key="i">
                                        <div class="row listing-place">
                                            <div  :title="rs.name" class="col-md-3 text-center" style="overflow: hidden;height: 120px" @click="selectBusiness(rs.buisness.id, rs.canBook)">
                                                <img v-if="rs.buisness.media[0]" @error="rs.buisness.media[0].thumb = rs.buisness.media[0].url;$forceUpdate();" :src="rs.buisness && rs.buisness.media && rs.buisness.media[0] && rs.buisness.media[0].thumb ? rs.buisness.media[0].thumb 
                                                    : '/assets/images/logo-icon.png'" style="max-height: 120px" />
                                                   
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="text-ellipsis w-100 privacy-header"  @click="selectBusiness(rs.buisness.id, rs.canBook)" :title="rs.name">{{rs.name}}</h3>
                                                
                                                <div class="row">
                                                    <div class="col-sm-12"  @click="selectBusiness(rs.buisness.id, rs.canBook)" :title="rs.name">
                                                        <!-- <stars :value="rs.buisness.rate"></stars> -->
                                                    </div>
                                                    <div class="col-sm-12 my-2" v-if="rs.buisness.location.address" :title="rs.buisness.location.address">
                                                        <a class="inlineBlock botbor list-sub" :href="'https://maps.google.com/?q='+rs.buisness.location.latitude+','+rs.buisness.location.longitude" target="_blank">
                                                            <i class="fa pointer fa-map"></i>
                                                            {{rs.buisness.location.address}}
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-12 mb-2 " v-if="rs.buisness.location.phone">
                                                        <a class="list-sub" :href="'tel:'+rs.buisness.location.phone" >
                                                            <i class="fa pointer fa-phone"></i>
                                                            {{rs.buisness.location.phone}}
                                                        </a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-center fa-0-8x w-100 mb-2">
                                                    <span 
                                                        class="category-text text-ellipsis"
                                                        v-for="(iid, ii) in rs.buisness.categories_ids" :key="ii" 
                                                        v-if="categories[iid]"                                                  
                                                        v-html="(ii > 0 ? ', ': '')+categories[iid].name">
                                                    </span>
                                                </div>
                                                <div>
                                                    <button v-if="rs.allow_checkin" class="btn btn-outline-primary btn-sm btn-block" type="button" 
                                                        @click="selectBusiness(rs.buisness.id, false)">
                                                        <i class="fa fa-check"></i>
                                                        {{$t('Checkin')}}
                                                    </button>
                                                    <button 
                                                        v-if="rs.canBook" 
                                                        class="btn btn-outline-info btn-block btn-sm"  type="button" 
                                                        @click="selectBusiness(rs.buisness.id, true)">
                                                        <i class="fa fa-ticket"></i>
                                                        {{$t('Reserve')}}
                                                    </button>
                                                    <button 
                                                        v-if="!$root.$empty(rs.buisness.has_donation)" class="btn btn-outline-success btn-block btn-sm"  type="button" 
                                                        @click="selectBusiness(rs.buisness.id, 'donate')">
                                                        <i class="fa fa-paypal"></i>
                                                        {{$t('Donate')}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <a class="list-group-item waiting text-center" v-if="inProgress">
                                        <i class="fa fa-spin fa-spinner"></i>
                                    </a>
                                </div>
                            </div>
                            <div v-else-if="!loading && business">
                                <div class="alert alert-warning">
                                    <i class="fa fa-warning"></i>
                                    {{$t('noDataFound')}}
                                </div>
                            </div>
                            <div v-else>
                                <div class="animated-background mt-3"><div class="background-masker"></div></div>
                                <div class="animated-background mt-3"><div class="background-masker"></div></div>
                                <div class="animated-background mt-3"><div class="background-masker"></div></div>
                                <div class="animated-background mt-3"><div class="background-masker"></div></div>
                            </div>
                        </div>
                    </div>

                    <!-- business -->
                    <div class="" v-if="business && form.business_id && !success">
                        <div class="row">
                            <div :class="$i18n.locale == 'ar' ? ['reserve-right', 'col-sm-12', 'reserve-breadcrumb', 'text-left'] : ['col-sm-12', 'reserve-breadcrumb', 'text-left']">
                                <ul>
                                    <li>
                                        <router-link
                                            :to="{path: '/'}"
                                        >
                                            <a class="pointer">{{$t('Home')}}</a>
                                        </router-link>
                                    </li>
                                    <i 
                                        class="fa breadcrumb-icon"
                                        :class="$i18n.locale == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right'">
                                    </i>
                                    <li @click='unSelectBusiness()'>
                                        <a class="pointer ">{{$t('Reservations')}}</a>
                                    </li>
                                    <i 
                                        class="fa breadcrumb-icon"
                                        :class="$i18n.locale == 'ar' ? 'fa-chevron-left' : 'fa-chevron-right'">
                                    </i>
                                    <li><a class="text-muted">{{$t(selectedBusiness.buisness.name)}}</a></li>
                                </ul>
                            </div>
                        </div>


                        <div class="row mb-2">
                                <div class="mainBody-card col-12 row mouse">
                                    <div class="col-12 col-md-2">
                                        <img class="text-center" :src="selectedBusiness.buisness.media ? selectedBusiness.buisness.media[0].thumb : 'https://media.tarkett-image.com/large/TH_24567081_24594081_24596081_24601081_24563081_24565081_24588081_001.jpg'" alt="">
                                    </div>

                                    <div class="col-md-10 col-12  privacy-header py-1 text-left">
                                        <div>{{selectedBusiness.buisness.name}}</div>
                                        <div class="privacy-subheader py-1 text-underline">
                                            <router-link v-for="(iid, ii) in selectedBusiness.buisness.categories_ids" :key="ii"  v-if="categories[iid]"                                                  
                                                v-html="(ii > 0 ? ', ': '')+categories[iid].name" :to="{ name: 'SearchResults', params: { categoryId : iid , locale: $i18n.locale }}">
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <!-- <button class="btn btn-lg btn-primary" :class="'float-'+$t('xalign')" :disabled="loading"
                                    style="height: auto;width: 200px;" :title="$t('ChangeBusiness')" 
                                    @click="unSelectBusiness()">
                                        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
                                        {{$t('ChangeBusiness')}}
                                </button> -->

                                
                                <div class="col-12 mt-4" v-if="selectedBusiness.allReservation || !$root.$empty(selectedBusiness.buisness.has_donation)">                                    
                                        <!-- <h3 class="text-center">{{$t('AvailableServices')}}</h3>
                                        <div class="desc text-center">{{$t('ChooseAvailableServices')}}</div> -->
                                        <div class="btn-group btn-group-primary mb-4 " role="group">
                                            <button style="height: auto !important;"  v-if="selectedBusiness.allow_checkin" type="button" class="btn btn-outline-primary" 
                                                @click="selectReservation(false)"
                                                :class="{'active': !selectedBusiness.reservation}">
                                                <i class="fa fa-check"></i>
                                                {{$t('Checkin')}}
                                            </button>
                                            <button v-if="selectedBusiness.allReservation" style="height: auto !important;" type="button" class="btn btn-outline-info"
                                                @click="selectReservation(true)"
                                                :class="{'active': selectedBusiness.reservation === true}">
                                                <i class="fa fa-ticket"></i>
                                                {{$t('Reservation')}}
                                            </button>
                                            <button v-if="!$root.$empty(selectedBusiness.buisness.has_donation)" style="height: auto !important;" type="button" class="btn btn-outline-success"
                                                @click="selectReservation('donate')"
                                                :class="{'active': selectedBusiness.reservation === 'donate'}">
                                                <i class="fa fa-paypal"></i>
                                                {{$t('Donate')}}
                                            </button>
                                        </div>
                                </div>
                        </div>                        
                        <div class="row panel panel-default" v-if="selectedBusiness.reservation === true && selectedBusiness.times">
                            <div class="col-12 my-5">    
                                <div class="filter-heading">
                                    {{'Choose Time'|t}}
                                </div>
                            <div class="panel-body row">
                                    <!-- <div class="row"> -->
                                        <div class="col-md-6 col-12 mb-3">
                                            <!-- <input type="date" v-model="form.from_date" class="form-control text-input-bot" 
                                                @change="getTimes()"
                                                :min="$root.$moment().locale('en').format('YYYY-MM-DD')" required /> -->
                                                <datepicker :value="form.from_date" @selected="updateDate" :language="locales[$i18n.locale.toLowerCase()]"
                                                    :disabled-dates="selectedBusiness.disabledDates" :required="true"
                                                    input-class="form-control text-input-bot" />
                                            </div>
                                            <div class="col-md-6 col-12 mb-3" v-if="form.times">
                                                <select v-model="form.time" required class="form-control time select-input-bot" 
                                                    @change="selectedBusiness.counts = selectedBusiness.halls = null;$forceUpdate();">
                                                    <option :value="null">{{$t('selectTime')}}</option>
                                                    <option v-for="(time, i) in form.times" :key="i"
                                                        :value="time">{{time}}</option>
                                                </select>
                                            </div>
                                            <div class="col-12 my-3" @click="searchAvailable()">
                                                <button class="btn btn-success btn-lg" :disabled="loading || !form.times || !form.time || !form.from_date">
                                                    <i class="fa" :class="loading ? 'fa-spin fa-spinner' : 'fa-search'"></i>
                                                    {{$t('getPlaces')}}
                                                </button>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>

                                </div>
                        <div class="alert alert-info" v-if="!$root.$empty(selectedBusiness.buisness.has_donation)">
                            <i class="fa fa-info-circle"></i>
                            {{$t('askDonate')}}
                            <a class="pointer" @click="selectReservation('donate')">
                                {{$t('clickHere')}}
                            </a>
                        </div>
                        <div class="row" v-if="selectedBusiness.allReservation && selectedBusiness.reservation === true">
                            
                            <div class="col-12" v-if="selectedBusiness.buisness.categories_ids.includes(14)">
                                <div class="alert alert-warning">
                                    
                                    <div><i class="fa fa-warning"></i> {{$t('organizingPurposesText')}}</div>
                                    <div><i class="fa fa-info-circle"></i> {{$t('followSafetyMeasures')}}</div>
                                </div>
                            </div>
                            <!-- <hr />
                            <hr /> -->
                            <div class="col-sm-12" v-if="selectedBusiness.buisness.halls.length > 1" id="hallsarea">                                
                                <h3 class="my-3">{{$t('Halls')}}</h3>
                                <div class="btn-group ltr mb-4" role="group">
                                    <button style="height: auto !important;" type="button" class="btn" 
                                        v-for="(hall, i) in selectedBusiness.buisness.halls" :key="i"
                                        @click="selectHall(hall)"
                                        :class="{'active': form.hall_id == hall.id, 'disabled': loading, 'btn-outline-primary': true}">
                                        {{hall.name || ('Hall #'+hall.id)}}
                                    </button>
                                </div>
                            </div>
                            
                            <div class="col-12 mb-4" v-if="selectedBusiness.buisness.categories_ids.includes(14)">
                                <div class="row ">
                                    <div 
                                        class="mb-2 col-md-6"
                                        v-for="(day, f) in fridays" :key="f"
                                    >
                                        <div class="list-group panel panel-default">
                                            <div class="list-group-item list-group-item-secondary panel-heading text-center">
                                                {{day.format("dddd DD/MM")}}
                                            </div>
                                            <div class="list-group-item list-group-item-action pointer" 
                                                :class="{'disabled': loading, 'active': selectedTime == time.id+'|'+day.format('YYYY-MM-DD')}"
                                                @click="pickTime(time, day)"
                                                v-for="(time, t) in selectedBusiness.buisness.times" :key="t">
                                                <i class="fa fa-spin fa-spinner" v-if="selectedTime == time.id+'|'+day.format('YYYY-MM-DD') && loading"></i>

                                                {{time.name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 mb-4" v-else-if="selectedBusiness.counts">
                                <div class="alert alert-danger mt-2 mb-3" v-if="selectedBusiness.halls && selectedBusiness.halls && $root.$len(selectedBusiness.halls[form.hall_id]) == 0">
                                    {{$t(selectedBusiness.buisness.halls.length > 1 ? 'noRoomForBookingInHall' : 'noRoomForBooking')}}
                                </div>
                                <div class="row" v-else>
                                    <div class="col-sm-6">
                                        <div class=" mx-1 panel panel-pri" id="avaTables">
                                            <div class='panel-heading'>{{'Available Tables'|t}}</div>
                                            <div 
                                                id='ScrollDown'
                                                class="badge max-width ma-2 col-sm-3 pointer prevent-close"                                     
                                                v-for="(count, type, i) in selectedBusiness.counts[form.hall_id]" :key="i"
                                                :title="$t('blocksPersons', {count: type, remaining: count})">
                                                <!-- <i class="fa fa-ticket"></i> -->
                                                <div data-toggle="dropdown">
                                                    <div class="fa-1x">{{type}}</div>
                                                    <img src="../../assets/images/table.svg" style="width: 45px" />
                                                </div>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item p-3 pointer" @click="toggleBlocks(block, type)" 
                                                        v-for="(block,id,ii) in selectedBusiness.halls[form.hall_id][type] || []" :key="ii">
                                                            <i class="fa fa-ticket list-icon"></i>
                                                            <strong class="list-icon">{{block}}</strong>
                                                            <i class="fa fa-check-circle text-success list-icon" v-if="form.numbersList && form.numbersList[block]"></i>
                                                    </a> 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="form.numbersList">
                                        <div class="mx-1 panel panel-pri">
                                            <div class="panel-heading">
                                                {{$t('Reservations')}}
                                            </div>
                                            <div class="panel-body">
                                                <div class="list-group">
                                                    <div class="list-group-item" v-for="(type,block,i) in form.numbersList" :key="i">
                                                        <i class="fa fa-check-circle text-success"></i>
                                                        {{$t('BookNumberContainPErsons', {
                                                            number: block,
                                                            count: type
                                                        })}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                


                                
                            </div>
                        </div>
                        
                        <!-- <hr />
                        <hr /> -->
                        <div class='panel panel-default' v-if="(!selectedBusiness.reservation 
                        || (selectedBusiness.reservation === true && form.time_id && form.hall_id)
                        || (selectedBusiness.reservation === true && form.numbersList && form.hall_id)) && (selectedBusiness.allow_checkin || selectedBusiness.canBook)">
                            <div class="filter-heading text-center pt-2 mb-30 body" id="AttendeesInfoBox">
                                {{$t('AttendeesInfo')}}
                                <span class="badge badge-warning" v-if="!loading && (selectedTime || form.numbersList)">
                                    {{$root.$len(form.attenders)}} / 
                                    {{availables}}
                                </span>
                            </div>
                            <div class="" v-if="selectedBusiness.reservation && availables == 0 && !form.numbersList" id="noRoomForBooking">
                                <div class="alert alert-warning ">
                                    <i class="fa fa-times-circle fa-2x"></i>
                                        {{$t('noRoomForBooking')}}
                                        {{$t('yourGoingToBeOnHold')}}
                                </div>
                            </div>
                            <form class="row" @submit.prevent="saveAttends()" v-if="selectedForm" id="saveAttends">
                                <div 
                                    v-for="(col,key, i) in columns" :key="i"
                                    :class="`col-${col.class}`"
                                >
                                    <label :class="((col.required && (col.required == 'one' || (col.required == 'reservation-one' && selectedBusiness.reservation)) && 
                                        (!form.attenders[firstOne] || !form.attenders[firstOne][key])) ? true : (col.required === true || null)) ? ['required', 'input-label'] : 'input-label'">{{$t('col_'+key)}}</label>
                                    <input v-if="!col.mask" :type="col.type"
                                    @blur="validateData(col, key)"
                                    :id="'col_'+key"
                                    :class="{'is-invalid': col.error, 'is-valid': col.valid}"
                                    :readonly="loading" 
                                    :required="(col.required && (col.required == 'one' || (col.required == 'reservation-one' && selectedBusiness.reservation)) && 
                                        (!form.attenders[firstOne] || !form.attenders[firstOne][key])) ? true : (col.required === true || null)" class="form-control" 
                                        :placeholder="$t('col_'+key)" v-model="selectedForm[key]" />     

                                    <input v-else :type="col.type"
                                    @blur="validateData(col, key)"
                                    :id="'col_'+key"
                                    :class="{'is-invalid': col.error, 'is-valid': col.valid}"
                                    :readonly="loading" 
                                    v-mask="col.mask"
                                    :masked="true"
                                    :required="(col.required && col.required == 'one' && 
                                        (!form.attenders[firstOne] || !form.attenders[firstOne][key])) ? true : (col.required === true || null)" class="form-control" 
                                        :placeholder="$t('col_'+key)" v-model="selectedForm[key]" /> 
                                     <small class="text-danger" v-if="col.error">
                                        {{col.error}}
                                    </small>
                                </div>
                                <div class="col-12 text-left p-3">
                                    <button class=' btn btn-lg btn-success' type="submit">
                                        {{'saveAttender'|t}}
                                    </button>
                                    <a  class="btn btn-light float-right" v-if="$root.$len(form.attenders) > 0" @click="cancelAttender()">
                                        {{$t('Cancel')}}
                                    </a>
                                </div>
                            </form>
                            
                            <form v-else class="contact-form panel-body" @submit.prevent="submit()">
                                <button type="button" 
                                :disabled="loading || (selectedBusiness.reservation && ((availables - $root.$len(form.attenders)) <= 0 
                                    && !selectedBusiness.buisness.categories_ids.includes(14)))" class="btn btn-outline-success mb-2" @click="attenderForm()">
                                    <i class="fa fa-plus-circle"></i>
                                    {{$t('addAttender')}} 
                                    <span class='text-warning ' v-if="selectedBusiness.reservation && (availables - $root.$len(form.attenders)) >= 0">
                                        ({{availables - $root.$len(form.attenders)}})
                                    </span>
                                </button>
                                <ul class="list-group pb-3">
                                    <li class="list-group-item  list-group-item-action" 
                                        v-for="(rs,id,i) in form.attenders" :key="i">
                                        <div class="row listing-attendee">
                                            <div class="col-sm-4">
                                                <i class="fa fa-user"></i>
                                                {{rs.name}}
                                            </div>
                                            <div class="col-sm-4">
                                                <i v-if="rs.phone" class="fa fa-phone"></i>
                                                {{rs.phone || ''}}
                                            </div>
                                            <div class="col-sm-2" v-if="!loading">
                                                <i class="fa fa-pencil pointer" @click="attenderForm(id)"></i>
                                                &nbsp;&nbsp;
                                                <i  v-if="$root.$len(form.attenders) > 1 && i > 0" @click="removeAttender(id)" class="fa fa-trash pointer text-danger"></i>
                                            </div>
                                            <div class="col-sm-2" v-else>
                                                <i class="fa fa-spin fa-spinner"></i>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input type="checkbox" class="inlineBlock" required />
                                        &nbsp;
                                        {{$t('agreeConditions')}}&nbsp;
                                        <a class="pointer" @click="openModal">
                                            {{$t('conditions')}}
                                        </a>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="submit" class="btn btn-success btn-lg" :disabled="loading" :value="$t('submit')">
                                </div>
                            </form>
                        </div>
                        <div class="row mb-2" v-else-if="selectedBusiness.buisness && 
                            selectedBusiness.reservation === 'donate'">
                            <div class="col p-5">
                                <Donate 
                                    :email="selectedBusiness.buisness.paypal_donation_mail" 
                                    :url="selectedBusiness.buisness.paypal_donation_url" 
                                    @submitted="() => { selectReservation(false); }" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
 
        
    </section>

    <Donate 
        v-else-if="selectedBusiness"
        :email="selectedBusiness.buisness.paypal_donation_mail" 
        :url="selectedBusiness.buisness.paypal_donation_url" 
        :start="$root.reservation === 'auto-reservation'"
        @submitted="() => { selectReservation(false); }" />

    <div class="modal pb-9" id="termsCond"  tabindex="-1" aria-labelledby="termsCondLabel" role='dialog' aria-hidden="true"  >
            <div class="modal-dialog" v-if="business && selectedBusiness" :class='width == "sm" ? "w-100" : ["modal-xl", "mx-auto"]'>
                <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title modal-header-custom" id="termsCondLabel">{{$t('conditions')}} ({{selectedBusiness.name}})</h3>
                    <a type="button" class="close" @click="closeModal"  aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li class="list-group-item ">
                            {{$t('over12')}}
                        </li>
                        <li class="list-group-item ">
                            {{$t('noDiseases')}}
                        </li>
                        <li class="list-group-item ">
                            {{$t('wearmask')}}
                        </li>
                        <li class="list-group-item ">
                            {{$t('respectSefety')}}
                        </li>
                        <li class="list-group-item ">
                            {{$t('followSupervisors')}}
                        </li>
                        <li class="list-group-item " v-if="selectedBusiness.buisness.categories_ids.includes(14)">
                            {{$t('performAblution')}}
                        </li>
                        <li class="list-group-item " v-if="selectedBusiness.buisness.categories_ids.includes(14)">
                            {{$t('bringCarpet')}}
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" @click='closeModal' class="btn btn-secondary">{{$t('Close')}}</button>
                </div>
                </div>
            </div>
    </div>
    <iframe id="printing"
        style="opacity: 0;"
        v-if="print && selectedBusiness.tickets"
        @load="printStatus = false;$forceUpdate()"
        
        class="w-100" 
        :src="$root.$domain+'businesses/booking/'+print+'/'+selectedBusiness.tickets.id+'/'+selectedBusiness.tickets.code+'?lang='+$i18n.locale" 
        frameborder="0"></iframe>

    <!-- Attach Messager helper -->
    <Messager /> 
</div>
</template>


<script>
import http from "../../Helpers/Api"
import Donate from "@/components/misc/Donate"
import { helpers } from '../../Helpers';
import Messager from "../../Support/Messager"
import Datepicker from 'vuejs-datepicker';
import {en, ar, de} from 'vuejs-datepicker/dist/locale'


export default {
    components: {
        // stars,        
        Donate,
        Datepicker,
        Messager
    },
    mixins: [helpers],
    data() {
        return {
            locales: {en, ar, de},
            firstOne: null,
            form: {
                business_id: null,
            },
            filter: {
                category_id: null,
                city_id: null,
                bookOnly: null,
            },
            oldFilter: {
                category_id: null,
                city_id: null,
                bookOnly: null,
            },
            business: null,
            allBusiness: null,
            allBusinessIndexs: null,
            selectedBusiness: null,
            length: null,
            categories: null,
            cities: null,
            loading: false,
            columns: {
                name: {type: 'text', required: true, class: '6'},
                phone: {type: 'tel', required: "one", class: '6', mask: '49###############'},
                email: {type: 'email', required: "reservation-one" , class: '12'},
                street: {type: 'text', required: false, class: '6'},
                houseNumber: {type: 'text', required: false, class: '6'},
                postalcode: {type: 'number', required: false, class: '6'},
                area: {type: 'text', required: false, class: '6'},      
            },
            selectedForm: null,
            selectedFormId: null,
            success: false,
            print: null,
            printStatus: false,
            fridays: {},
            selectedTime: null,
            selectedTimeElement: null,
            availables: 0,
            // types: '3,4',
            filter: {},
            title: document.title,
            selectedBusinessID: null
        }
    },
    methods: {
        updateDate(val)
        {
            // console.log("date", val, moment(val).format("YYYY-MM-DD"));
            this.form.from_date = moment(val).format("YYYY-MM-DD");
            this.getTimes();
        },
        validateData(col, key)
        {
            let val = this.selectedForm[key];
            if(col && col.error) col.error = null;
            if(col && col.valid) delete col.valid;
            switch(col.type.toLowerCase())
            {
                case 'tel':
                    if(!empty(val))
                    {
                        this.loading = true;
                        col.loading = true;
                        this.$forceUpdate();
                        http.api('validate/phone/'+val).then(res => {
                            if(res.success)
                            {                    
                                col.valid = true;
                            }
                            else 
                            {
                                $('#col_'+key).select();
                                col.error = this.$t('notVaildNumber');
                            }
                            this.loading = false;
                            delete col.loading;
                            this.$forceUpdate();
                        });
                    }
                    else this.$forceUpdate();
                    
                    break;
                    default:
                        this.$forceUpdate();
            }
        },
        update()
        {
            window.setTimeout(() => {
                this.$forceUpdate();
            }, 400);
        },
        pickTime(time, day)
        {
            if(this.loading) return false;
            this.loading = true;
            this.$forceUpdate();

            this.selectedTimeElement = {time: time, day: day};
            this.selectedTime = time.id+"|"+day.format("YYYY-MM-DD");
            let theDay = day.clone();
            theDay.locale("en");

            this.form.time_id = time.id;
            this.form.on_date = theDay.format("YYYY-MM-DD");

            http.api('business/booking/'+this.selectedBusiness.business_id+'/search', {
                from_date: theDay.format("YYYY-MM-DD"),
                hall_id: this.form.hall_id,
                time_id: this.form.time_id,
                count: true
            }).then(res => {
                if(res.success)
                {                    
                    this.availables = res.data || 0;
                    gotoContent("#AttendeesInfoBox", {top: -100});
                }
                this.loading = false;
                this.$forceUpdate();
            });
        },
        selectReservation(status)
        {
            if(status === true && !this.selectedBusiness.allReservation)
            {
                status = false;
            }
            if(this.loading) return false;
            if(status === 'auto-reservation')
            {
                if(empty(this.selectedBusiness.buisness.has_donation))
                {
                    this.$root.reservation = null;
                    $('.preloader').fadeOut(1000);
                }
                status = false;
            }
            this.selectedBusiness.reservation = status || false;
            if(status === true)
            {
                this.selectHall(this.selectedBusiness.buisness.halls.find(h => h));
            }
            else
            {
                delete this.form.hall_id;
                
            }
            this.form.attenders = {};
            this.attenderForm();
        },
        selectHall(hall)
        {
            if(this.loading) return false;
            this.form.hall_id = hall.id;
            if(this.selectedTimeElement)
            {
                this.pickTime(this.selectedTimeElement.time, this.selectedTimeElement.day);
            }
            else this.$forceUpdate();
        },
        isThisInFuture(targetDayNum) {
            // param: positive integer for weekday
            // returns: matching moment or false
            const todayNum = moment().isoWeekday();  

            if (todayNum <= targetDayNum) { 
                return moment().isoWeekday(targetDayNum);
            }
            return false;
        },
        findNextInstanceInDaysArray(daysINeed) {
            // iterate the array of days and find all possible matches
            const tests = daysINeed.map(this.isThisInFuture);

            // select the first matching day of this week, ignoring subsequent ones, by finding the first moment object
            const thisWeek = tests.find((sample) => {return sample instanceof moment});

            // but if there are none, we'll return the first valid day of next week (again, assuming the days are sorted)
            return thisWeek || moment().add(1, 'weeks').isoWeekday(daysINeed[0]);;
        },
        searchBusiness(page)
        {
            if(this.inProgress) return false;
            if(page === true) this.filter.page++;
            else if(page) this.filter.page = parseInt(page);
            if(!this.filter.page) this.filter.page = 1;

            if(this.filter.page == 1) 
            {
                this.loading = true;
                this.business = null;
                this.allBusiness = [];
                this.allBusinessIndexs = null;
                this.length = 0;
            }
            this.inProgress = true;
            // this.filter.types = this.types;
            // this.filter.withCities = "true";
            this.filter.times = "true";
            this.filter.halls = "true";
            this.filter.paginate = 20;

            this.filter.categories = this.categories ? null : "true";
            this.filter.cities = this.cities ? null : "true";
            // show(this.filter.page, "page");
            this.$forceUpdate();
            http.api('searchBusinesses', this.filter).then(res => {
                // console.log("res", res);
                if(res.success)
                {                    
                    if(!this.business) this.business = {};
                    if(!this.allBusiness) this.allBusiness = [];
                    if(!this.length || this.length == 0) this.length = res.data.last_page; 
                    // this.allBusiness = [];
                    this.allBusinessIndexs = {};
                    if(res.categories)
                    {
                        this.categories = {}; 
                        res.categories.forEach(rs => {
                            this.categories[rs.id] = rs;
                        });
                    }
                    
                    if(res.cities)
                    {
                        this.cities = {}; 
                        res.cities.forEach(rs => {
                            this.cities[rs.id] = rs;
                        });
                    }

                    res.data.data.forEach(rs => {
                        rs.canBook = rs.buisness.allow_booking && rs.buisness.times && rs.buisness.halls && rs.buisness.times.length > 0 && rs.buisness.halls.length > 0;
                        rs.allow_checkin = rs.buisness.allow_checkin;
                        this.allBusiness.push(rs);                        
                        this.allBusinessIndexs[rs.business_id] = this.allBusiness.length - 1;   
                        this.business[rs.business_id] = this.$root.$copy(rs)                     
                    });
                    // this.nextPage();

                    this.loading = this.inProgress = false;
                    if(this.filter.business_id) this.selectBusiness(this.filter.business_id, true);
                }
                else console.error(res);

                this.$forceUpdate();
            });
        },
        nextPage()
        {  
            if(this.length > this.filter.page)
                this.searchBusiness(true);
        },
        resetBusiness()
        {
            this.business = {};
            this.firstOne = null;
            this.selectedBusiness = null;
            this.print = null;
            this.success = null;
            this.length = 0;   
            this.loading = false;
            this.inProgress = false;
            this.selectedTime = null;
            this.selectedTimeElement = null;
            this.$forceUpdate();         
        },
        findMatch()
        {
            this.searchBusiness(1);
        },
        selectBusiness(id, reservation)
        {
            this.form.business_id = this.allBusiness[this.allBusinessIndexs[id]] ? id : null;
            this.selectedBusiness = this.allBusiness[this.allBusinessIndexs[id]] ? this.$root.$copy(this.allBusiness[this.allBusinessIndexs[id]]) : null;

            // $("#termsCond").modal('show');

            this.form.attenders = {};
            this.form.source_id = 3;
            this.selectedBusiness.allReservation = this.selectedBusiness.buisness.allow_booking && this.selectedBusiness.buisness.times && this.selectedBusiness.buisness.times.length > 0 && this.selectedBusiness.buisness.halls && this.selectedBusiness.buisness.halls.length > 0;
            this.loading = false;
            if(this.filter.reservation == 'donate' && empty(this.selectedBusiness.buisness.has_donation))
            {
                this.filter.reservation = null;
            }

            if(!this.selectedBusiness.allow_checkin && !reservation && this.selectedBusiness.allReservation)
            {
                reservation = true;
            }
            // console.log("selectReservation", this.filter.reservation || reservation)
            this.selectReservation(this.filter.reservation || reservation);
            this.attenderForm();
            this.selectedBusiness.buisness.categories_types_ids = [];
            this.selectedBusiness.buisness.categories_ids.forEach((id) => {
                if(this.categories[id])
                {
                    this.selectedBusiness.buisness.categories_types_ids.push(this.categories[id].type_id);
                }
            });

            //reservations
            if(this.selectedBusiness.buisness.categories_ids && (this.selectedBusiness.buisness.categories_types_ids.includes(2) || this.selectedBusiness.buisness.categories_types_ids.includes(3)))
            {
                this.selectedBusiness.times = {};
                this.selectedBusiness.disabledDates = {to: moment().add(-1, 'days').toDate()};
                let days = {};
                for(var i = 0;i <= 6;i++)
                {
                    days[i] = i;
                }
                let spent_time = this.selectedBusiness.buisness.spent_time || 60;
                if(this.selectedBusiness.buisness.times)
                {
                    $.each(this.selectedBusiness.buisness.times, (i, t) => {
                        //generate times
                        let from = moment(t.from, "HH:mm:ss").locale("en");
                        let to = moment(t.to, "HH:mm:ss").locale("en");
                        if(from > to) to.add(1, "day");
                        let times = [];
                        while(true)
                        {                            
                            times.push(from.format("HH:mm"));
                            if(from >= to) break;
                            from.add(spent_time, "minutes");
                        }
                        
                        
                        $.each(t.days, (dd, day) => {
                            if(days[day]) delete days[day];
                            if(!this.selectedBusiness.times[day]) this.selectedBusiness.times[day] = [];
                            $.each(times, (iii, time) => {
                                if(!inArray(time, this.selectedBusiness.times[day]))
                                    this.selectedBusiness.times[day].push(time);
                            });
                        });
                        
                    });
                    if(this.$root.$len(days) > 0)
                    {
                        this.selectedBusiness.disabledDates.days = Object.values(days);
                    }
                    // console.log("this.selectedBusiness.disabledDates", this.selectedBusiness.disabledDates);
                    this.form.from_date = moment().locale("en").format('YYYY-MM-DD');
                    
                    this.getTimes();
                    // show(this.selectedBusiness.times, "this.selectedBusiness.times");
                }
            }
            this.$forceUpdate();
            this.chnageState(this.selectedBusiness.name, id);
            
            gotoContent("#AttendeesInfo", {top: -100});
        },
        unSelectBusiness()
        {
            if(this.selectedBusinessID)
            {
                this.loading = true;
                this.$forceUpdate();
                
                window.location.href = (this.$i18n.locale ? "/"+this.$i18n.locale : '') + "/reservations";
                return false;
            }
            this.form.business_id = null;
            this.selectedBusiness = null;
            this.form.attenders = {};
            delete this.filter.business_id;
            delete this.filter.reservation;
            
            
            this.$forceUpdate();
            this.chnageState()
            // this.searchBusiness();
        },
        attenderForm(id)
        {
            if(!id) 
            {
                this.selectedForm = {
                    "area": "",
                    "name": "",
                    "email": "",
                    "phone": "",
                    "street": "",
                    "postalcode": "",
                    "houseNumber": ""
                };
                id = Date.now();
            }
            else this.selectedForm = this.$root.$copy(this.form.attenders[id]);
            this.selectedFormId = id;
            if(!this.firstOne) this.firstOne = id;
            this.$forceUpdate();
        },
        removeAttender(id)
        {
            delete this.form.attenders[id];
            this.$forceUpdate();
        },
        cancelAttender()
        {
            this.selectedForm = null;
            this.selectedFormId = null;
            this.$forceUpdate();
            gotoContent("#AttendeesInfoBox", {top: -100});
        },
        saveAttends()
        {            
            this.form.attenders[this.selectedFormId] = this.selectedForm;
            this.cancelAttender();
            console.log(this.form.attenders);   
        },
        submit()
        {            
            if(this.selectedBusiness.reservation && !this.form.numbersList && !this.form.time_id)
            {
                this.$root.$msg.error("pleaseSelectTheHall");
                return false;
            }
            // else if(this.selectedBusiness.reservation && this.availables - len(this.form.attenders) < 0)
            // {
            //     this.$root.$msg.error('noRoomForBooking');
            //     return false;
            // }

            let form = this.$root.$copy(this.form);
            if(this.selectedBusiness.reservation && this.form.numbersList)
            {
                form.numbers = Object.keys(form.numbersList);
                delete form.numbersList;
                form.persons = this.availables;
                form.from = form.time;
                form.on_date = form.from_date;
                delete form.time;
                delete form.from_date;
            }
            else form.persons = len(form.attenders);
            
            this.loading = true;
            this.$forceUpdate();

            
            if(form.times) delete form.times;
            // show(form, "form");
            http.api(this.selectedBusiness.reservation ? 'business/booking/book' : 'attends/checkin', form)
            .then(res => {
                if(res.success)
                {
                    this.selectedBusiness.tickets = this.selectedBusiness.reservation ? res.data : null;
                    this.success = true;    
                    
                    gotoContent("#AttendeesInfo", {top: -100});
                    this.$forceUpdate();
                    window.setTimeout(() => {
                        this.$forceUpdate();
                    }, 350);
                }
                else
                {
                    this.$root.$msg.error(res.message || 'noRoomForBooking');
                    this.loading = false;
                    this.$forceUpdate();
                }
                
            });
        },
        printing(type)
        {
            this.print = null;
            this.$forceUpdate();
            this.$nextTick(() => {
                this.printStatus = true;
                this.print = type || 'print';
                this.$forceUpdate();
            });
        },
        pdf()
        {
            this.printing('pdf');
            window.setTimeout(() => {
                this.printStatus = false;
                this.$forceUpdate();
            }, 1500);
        },
        searchAvailable()
        {
            if(!this.form || !this.form.from_date || !this.form.time) return false;

            this.loading = true;
            this.$forceUpdate();
            
            http.api('business/booking/'+this.form.business_id+'/search', {
                from_date: this.form.from_date,
                time: this.form.time,
                show_details: true,
            }).then(res => {
                this.selectedBusiness.halls = {};
                this.selectedBusiness.counts = {};
                if(res.success)
                {
                    $.each(res.data, (i, d) => {
                        this.selectedBusiness.halls[d.id] = {};
                        this.selectedBusiness.counts[d.id] = {};
                        $.each(d.blocks || {}, (ih,rs) => {
                            if(!this.selectedBusiness.counts[d.id][rs.persons]) 
                            {
                                this.selectedBusiness.counts[d.id][rs.persons] = 0;   
                                this.selectedBusiness.halls[d.id][rs.persons] = {};   
                            }
                            this.selectedBusiness.counts[d.id][rs.persons]++;
                            this.selectedBusiness.halls[d.id][rs.persons][rs.number] = rs.number;
                        })
                    });

                    // show(this.selectedBusiness.halls, "this.selectedBusiness.halls");
                }
                this.loading = false;
                gotoContent("#hallsarea", {top: -100});
                this.$forceUpdate();
            });
        },
        getTimes()
        {
            if(this.form.from_date)
            {
                let w = moment(this.form.from_date).day();
                this.form.times = this.selectedBusiness.times[w] || [];
            }
            else this.form.times = null;            
            if(this.form.time) this.searchAvailable();
            else this.form.time = null;
            this.$forceUpdate();
        },
        toggleBlocks(block, type)
        {
            if(!this.form.numbersList) 
            {
                this.availables = 0;
                this.form.numbersList = {};
            }
            if(this.form.numbersList[block]) 
            {
                delete this.form.numbersList[block];
                if(len(this.form.numbersList) == 0) this.form.numbersList = null;
                this.availables -= type;
            }
            else 
            {
                this.form.numbersList[block] = type;
                this.availables += parseInt(type); 
            }


            window.scrollTo({
                    top: 1000,
                    behavior: 'smooth'
                });
            this.$forceUpdate();
        },
        callBackButton()
        {
            // show("back clecked");
            if(this.form.business_id) 
            {
                this.unSelectBusiness();
                return false;
            }
            else return false;
        },
        chnageState(title, business_id)
        {
            window.history.pushState(business_id ? {business_id: business_id} : {}, 
                title || '', "/"+this.$i18n.locale+"/"+(business_id || 'reservations'));

            document.title = (title ? title + " - " : '')+this.title;
        },
        closeModal() {
            $("#termsCond").modal('hide');
        },
        openModal() {
            $("#termsCond").modal('show');
        }
    },
    computed: {
        width() {
            return this.$store.getters.width;
        }
    },
    created() {
        if(this.$root.reservation === 'auto-reservation')
        {
            $('.preloader').addClass('preactive').show();   
        }
    },
    mounted() {
        http.locale = this.$i18n.locale;
        this.$loadScript("../../js/bootstrap.bundle.js");
        this.$root.reservation = this.$route.query && this.$route.query.reservation ? this.$route.query.reservation : null;
        if(this.$root.business_type)
        {
            this.types = this.$root.business_type;
        }
        if(this.$root.business_id || (this.$route.params.id && isNumeric(this.$route.params.id)))
        {
            this.selectedBusinessID = this.filter.business_id = this.$root.business_id || this.$route.params.id;
        }
        if(this.$root.reservation)
        {            
            this.filter.reservation = this.$root.reservation;
        }

        // console.log("this.$root.reservation", this.$root.reservation);

        window.onpopstate = this.callBackButton;

        this.searchBusiness();
        // if(this.$route.params.business.id){
        //     this.selectBusiness(this.$route.params.business.id)
        //     console.log()
        // }
        this.fridays = {};
        let day = this.findNextInstanceInDaysArray([5]);
        for(var i = 1;i <= 4;i++)
        {
            this.fridays[i] = day.clone();
            day.add(7, "days");
        }

    },
    
    // beforeDestroy () {
    //     document.removeEventListener("backbutton", this.callBackButton);
    // },
    directives: {
        scrolling(that, bind, vnode)
        {            
            
            $(that).scroll(() => {
                let height = $(that).find('div.list-group:eq(0)').height();
                if(height < $(that).scrollTop()+500) {
                   vnode.context.nextPage();
                    // show("call");
                }                
            });
        },
    },
}
</script>

<style scoped >
    .pointer
    {
        cursor: pointer;
    }
    .pb-10{
        padding-bottom: 100px;
    }
    .mb-10{
        margin-bottom: 100px;
    }
    .mt-10{
        margin-top: 100px;
    }
    .mb-4{
        margin-bottom: 40px;
    }
    .mt-4{
        margin-top: 40px;
    }
    .ma-2{
        margin: 35px 10px;
    }
    .mt-2{
        margin-top: 20px;
    }
    .pa-4{
        padding: 30px;
    }
    .required::after{
        content: ' *';
        color: red;
       font-size: 18px; 
    }
    .addon {
       padding: 0px 10px;
    }
    .mx-1{
        margin: 5px 15px; 
    }
    .my-2{
        margin: 10px 0px;
    }
    .botbor{
        border-bottom: grey dashed 1px;
        padding: 3px;
    }
    .table-menu{
        max-width: 100px;
    }
    .list-icon{
        padding: 0px 10px;
        font-size: 14px;
    }
    .max-width{
        max-width: 80px;
    }

    
    .list-group{
        border: 1px 0px rgba(160,160,160,0.4) solid;
        border-radius: 8px;
    }
    .list-group-item{
        border: 0;
    }
    .inlineBlock
    {
        display: inline-block !important;
    }

    .ltr
    {
        direction: ltr !important;
    }
    .fa-0-8x
    {
        font-size: 0.8em;
    }
    .disabled, .disabled *
    {
        cursor: no-drop !important;
    }
    .dropdown-item{
        padding: 0px
    }

    @-webkit-keyframes placeHolderShimmer{
        0%{
            background-position:-468px 0
        }
        100%{
            background-position:468px 0
        }
    }

    @keyframes placeHolderShimmer{
        0%{
            background-position:-468px 0
        }
        100%{
            background-position:468px 0
        }
    }

    .animated-background {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: placeHolderShimmer;
        animation-name: placeHolderShimmer;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: #eeeeee;
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
        background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background: linear-gradient(to right,  #eeeeee 8%,#dddddd 18%,#eeeeee 33%);
        -webkit-background-size: 800px 104px;
        background-size: 800px 104px;
        height: 20px;
        position: relative;
    }

    .background-masker {
        background: #fff;
        position: absolute;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .modal-content
    {
        margin-top: 75px;
    }

    @media (max-width: 767px){
        .pa-4{
            padding: 5px;
        }
    }
    
    #AttendeesInfo a:hover, button:hover
    {
        text-decoration: none;
    }

    .dirs-rtl #saveAttends
    {
        direction: rtl;
    }
    .dirs-rtl #saveAttends div
    {
        text-align: right;        
    }
</style>