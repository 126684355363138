<template>

<div 
  class=" cart-contain container-fluid"
  :class="width != 'sm' ? ' mx-9' : ['mx-1', 'mb-8']"
>
  <div class="row">
    <div class="col-12">
      <div class="row  mt-5 ">
        <div class="col-12 col-lg-8 ">
          <div class="row card-sec mx-2 mb-3">
            <div class="col-12 ">
              <div class="pro-header mb-4 required" :class="t_ar">
                {{$t('paymentMethod')}}
              </div>
              <div 
                class="mt-5"
                ref="paypal_button_container" 
                id='paypal_button_container' 
              ></div>

            </div>
          </div>
        </div>
        <div 
          class="col-12 col-lg-4" 
          :class="width == 'sm' ? 'mt-3' : ''"  
        >
          <div class="card-sec row mx-2" :class="width == 'sm' ? 'p-2' : ''">
            <div class="col-12" :class="t_ar">
              <div class="pro-header mb-4">
                {{$t('summary')}}
              </div>
              <div class="opt-label">{{$t('TotalWithoutTax')}} <span :class="f_ar_reverse"> {{(parseFloat(data.subTotalWithNoTax)).toFixed(2)}} €</span></div>
              <div class="opt-label">{{$t('VAT')}} <span :class="f_ar_reverse">{{(parseFloat(data.VAT)).toFixed(2)}} €</span></div>
              <div class="opt-label">{{$t('subTotal')}} <span :class="f_ar_reverse"> {{(parseFloat(data.subTotal)).toFixed(2)}} €</span></div>
              <div class="opt-label mt-3">{{$t('delivery')}} <span :class="f_ar_reverse"> {{parseFloat(data.delivery_price) || 0}} €</span></div>
              <!-- <div class="opt-label">{{$t('CouponValue')}} <span :class="f_ar_reverse"> {{isNaN(data.couponValue) ? 0 : (data.couponValue).toFixed(2)}} €</span></div> -->
              
              <div class="opt-name text-center total-card mt-4">{{$t('total')}} € {{(data.total)}}</div>
            </div>
          </div>
        </div>
      </div>
      <NoPlaces  v-if='loading'/>
    </div>

  </div>
</div>
</template>


<script>
import { mapActions, mapMutations } from 'vuex';
import cookies from 'vue-cookies';
import NoPlaces from '@/components/misc/NoPlaces';

export default {
  components: {
    NoPlaces
  },
  data() {
    return {
      status: 'Pending',
      paypal_order_id: '',
      paypalAccess: '',
      fees: 0,
      transaction_id: '',
      // myId: 'Aae1rwB4-dRM-asGuN3s-6m2yd84Cdzc9gskNQou_UuPJok_ICnhuIvSmSPe9wLMftAlL-hoOXiNCbvG',
      // mySecret: 'EB7y20xflnl8tjQ-n9dEnNHBknHrituuF9HzERNoUUveixhReIR5TXnFYYDW9VTebQPiPi_SNwsbK_16'
    }
  },

  mounted() {
    this.loadPaypalScript();
    this.$store.dispatch('stopLoading')
  },

  computed: {
    data() {
      return this.$route.params;
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    width() {
      return this.$store.getters.width;
    },
    loading() {
      return this.$store.getters.loading; 
    }
  },

  methods: {
    ...mapActions(['getToken', 'createOrder', 'captureOrder', 'updatePayment']),
    ...mapMutations(['showMsg']),
    setLoaded () {
      this.loaded = true;
         window.paypal
          .Buttons({
            // fundingSource: paypal.FUNDING.PAYPAL,
            locale : this.$i18n.locale == 'de' ? 'de_DE' : 'en_US',
            style: {
              layout: 'horizontal',
              color: 'blue',
              label: 'checkout',
              shape: 'pill',
              // fundingicons: false,    /* true | false */
            },
            createOrder: (data, actions) => {
                return this.getToken({
                  client_id: this.data.client_id,
                  client_secret: this.data.client_secret
                }).then(res => {
                  this.paypalAccess = res.data.access_token;
                  return this.createOrder({
                    purchase_units: [
                      {
                        amount: {
                          currency_code: "EUR",
                          value: this.data.total,
                        },
                      },
                    ]
                  }).then(res => {
                    this.paypal_order_id = res.data.id;
                    return res.data.id;
                  }).catch(err => new Error(this.$t('ErrorCreateOrder')))
                }).catch(err => new Error(this.$t('ErrorGettingToken')))

            },
            onApprove: async (data, actions) => {
              console.log(this.$store.state.paypalToken, this.paypalAccess)
              this.$store.dispatch('startLoading');
              this.captureOrder({
                id: this.paypal_order_id,
                access: this.paypalAccess
              }).then(res => {
                  this.status = 'Paid';
                  this.order_id = this.data.order_id;
                  this.transaction_id = res.data.purchase_units[0].payments.captures[0].id;
                  try {
                    this.fees = res.data.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee.value;
                  } catch (error) {
                    this.fees = 0;
                  }
                  this.updatePayment({
                    order_id: this.order_id,
                    payment_id: this.data.payment_id,
                    secret_word: this.data.updateSecret,
                    status: this.status,
                    transaction_id: this.transaction_id,
                    fees: this.fees,
                  }).then(res => {
                    // console.log(res);
                    this.showMsg({
                      text: this.$t('paymentSuccessful'),
                      type: 'success'
                    });
                    this.$store.dispatch('stopLoading');
                    setTimeout(() => {
                      this.$router.push({
                        name: 'MyOrders',
                        params: {
                          id: cookies.get('hold')
                        }
                      });
                    }, 3000)
                  }).catch(err => new Error(this.$t('ErrorPayStatus')))
                })
            },
            onError: err => {
              this.showMsg({
                text:  err || this.$t('ProcedureError'),
                type: 'error'
              })
            },
            onCancel: (data) => {
              this.showMsg({
                text:  this.$t('PaymentCancel'),
                type: 'error'
              });
              setTimeout(() => {
                this.$router.push({
                  name: 'MyOrders',
                  params: {
                    id: cookies.get('hold')
                  }
                });
              }, 3000)
            }
          })
          .render(this.$refs.paypal_button_container);
     
    },
    loadPaypalScript() {
      const script = document.createElement("script");
      let src =`https://www.paypal.com/sdk/js?client-id=${this.data.client_id}&currency=EUR`;
      script.src = src;
      script.addEventListener("load", () => this.setLoaded());
      document.body.appendChild(script);
    },
  },



}

</script>