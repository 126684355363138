<template>
    <div class="pa-4">
        <form id="donate-form" 
            v-if="url && $root.$empty(email)"
            @submit="submiting($event)" 
            :target="start ? '_self' :'_blank'" 
            :action="url" method="get">
            <div class="alert alert-info">
                <i class="fa fa-check-circle"></i>
                {{'thanksForYourDonation'|t}}
            </div>
        </form>
        <form id="donate-form" 
            v-if="email && $root.$empty(url)"
            @submit="submiting($event)" 
            :target="start ? '_self' :'_blank'" 
            action="https://www.paypal.com/cgi-bin/webscr" method="post">
            
            <!-- Identify your business so that you can collect the payments. -->
            <input type="hidden" name="business" :value="email">

            <!-- Specify a Donate button. -->
            <input type="hidden" name="cmd" value="_donations">

            <!-- Specify details about the contribution -->
            <h3 class=" mb-2">{{'DonationPurpose'|t}}</h3>
            <input type="text" class="form-control" :placeholder="$t('DonationPurpose')" :disabled="loading" name="item_name_1" required />
            <input type="hidden" name="currency_code" value="USD">

            <!-- Display the payment button. -->
            <button class="btn btn-info btn-lg mt-2" type="submit" :disabled="loading">
                <i class="fa" :class="loading ? 'fa-spin fa-spinner' : 'fa-paypal'"></i>
                {{'Donate'|t}}
            </button>
        </form>
    </div>
</template>

<script>
export default {
    props: [
        "email",
        "url",
        "start"
    ],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        submiting()
        {
            this.loading = true;            
            this.$forceUpdate();
            
            window.setTimeout(() => {
                this.$emit("submitted", true);
            }, 1000);
        }
    },
    mounted() {
        // show(this.start, "this.start");
        if(this.start || (!empty(this.url) && empty(this.email)))
        {
            let form = $("#donate-form");
            if(!empty(this.url)) form.find('[name="item_name_1"]').val("Donate");
            form.submit();
        }
    },
}
</script>

<style>
.mt-2{
    margin-top: 20px;
}
.pa-4{
    padding: 40px;
}
.mb-2{
    margin-bottom: 20px;
}
</style>