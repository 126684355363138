<template>
    <div class="p-2">
        <div class="floating" v-if="messages && $root.$len(messages) > 0" >
            <div 
                v-for="(m, id, i) in messages" :key="i" :id="'msg-alert-'+id"
                
                class="alert  pointer mb-2" :class="'alert-'+m.class">
                    <button @click="closeMessage(id)" type="button" class="close float-right ml-2" :title="closeText">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <i class="fa fa-1-5x" :class="m.class == 'success' ? 'fa-check-circle' : 'fa-times-circle'"></i>
                    <span style="font-size: 130%;" v-html="$t(m.message)"></span>
            </div>
        </div>
        <div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="id+'ModalLabel'" aria-hidden="true">
            <div class="modal-dialog" :class="dialogSize" role="document">
                <div class="modal-content">
                <div class="modal-header" v-if="title">
                    <h5 class="modal-title" :id="id+'ModalLabel'" :class="statusClass" v-html="$t(title)"></h5>
                    <button v-if="!noClose" type="button" class="close" data-dismiss="modal" :aria-label="closeText" :title="closeText">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p :class="statusClass" v-html="$t(message)"></p>
                </div>
                <div class="modal-footer">
                    <button v-if="!noClose" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t(closeText || "close")}}</button>
                    
                    <button v-for="(btn,index) in btns" :key="index" type="button" class="btn" :class="btn.status" @click="btnEvent(btn)">
                        {{btn.text|t}}
                    </button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .floating
    {
        position: fixed;
        left: 10px;
        z-index: 999999;
        min-width: 300px;
        top: 20px;
    }
</style>
<script>
import Vue from 'vue';
import VueI18nFilter from 'vue-i18n-filter'
Vue.use(VueI18nFilter)
export default {
    data() {
        var that = this;
        return {
            id: "messager",
            title: "System Message",
            message: "",
            messages: {},
            inline: false,
            statusClass: "",
            messageVisiablility: false,
            noClose: false,
            closeText: 'Close',
            dialogSize: '',
            btns: [],
            msg: {
                ok(message, status, title = null, onOk)
                {
                    that.show(message, title, status, onOk, "Ok");
                },
                ask(message, status, title, onYes, onNo = null, yesText = "Yes", noText = "No")
                {
                    that.btns = [];
                    that.addBtn(yesText, function(close)
                    {
                        if(onYes) onYes(close);
                        else close();
                    }, 'btn-'+status);
                    that.addBtn(noText, function(close)
                    {
                        if(onNo) onNo(close);
                        else close();
                    });
                    
                    that.show(message, title, status, that.btns, null);
                },
                success(message)
                {
                    that.setMessage(message, "success")
                },
                error(message)
                {
                    that.setMessage(message, "danger")
                },
            },
            on: {
                hide: null,
            }
        }
    },
    methods: {
        setMessage(msg, className)
        {
            let id = this.$root.$unique()+"-"+(Date.now());
            this.messages[id] = {
                message: msg,
                class: className,
                top: this.topMessage,
            };

            this.$forceUpdate();
            // show(this.messages, id);
            // this.$nextTick(() => {
                // gotoContent('#msg-alert-'+id, {top: -150});
            // });
            window.setTimeout(() => {
                this.closeMessage(id);
            }, 15000);
        },
        closeMessage(id)
        {
            if(this.messages[id] && !this.messages[id].deleted) 
            {
                this.messages[id].deleted = true;
                $('#msg-alert-'+id).fadeOut(() =>
                {
                    delete this.messages[id];
                    this.$forceUpdate();
                });
            }
        },
        startOver()
        {
            this.btns = [];
            this.title = "System Message";
            this.message = "";
            this.statusClass = "";
            this.noClose = false;
            this.closeText = "Close";
            this.dialogSize = '';
        },
        btnEvent(btn)
        {
            if(btn.call)
            {
                btn.call(this.evHide);   
            }
            else 
            {
                this.evHide();
            }
        },
        show(message, title, status, btns, closeText)
        {            
            if(this.messageVisiablility)
            {
                this.evHide(() => {
                    this.show(message, title, status, btns, closeText);
                });                
            }
            else
            {
                this.startOver();
                this.closeText = closeText || "Close";
                this.noClose = closeText === null;
                this.title = title || null;
                this.message = message;
                this.statusClass = 'text-'+(status || 'default');
                
                if(btns && isObject(btns) && !isFunction(btns) && btns.length > 0)
                {
                    this.btns = btns;
                }                
                else
                {
                    this.noClose = true;
                    this.addBtn("Ok", (close) =>
                        {
                            if(btns && isFunction(btns)) btns(close);
                            else close();
                        },
                    'btn-'+(status || 'default'));                    
                }
                this.$forceUpdate();
                this.evShow();
            }
        },
        evShow()
        {
            this.messageVisiablility = true;
            $("#"+this.id).modal({
                keyboard: false,
                backdrop: 'static',
            });
        },
        evHide(callBack = null)
        {
            this.on.hide = callBack;
            $("#"+this.id).modal("hide");
        },
        addBtn(text, onClick, status)
        {
            this.btns.push({
                text: text || 'Ok',
                status: status || 'btn-default',
                call: onClick
            });
        }
    },
    created() {
        this.$root.$msg = this.msg;
    },
    mounted() {
        var that = this;
        $('#'+this.id).on('hidden.bs.modal', function (e) {
            that.messageVisiablility = false;
            if(that.on.hide) 
            {
                that.on.hide();
                that.on.hide = null;
            }
            that.$forceUpdate();
        });

        
    },
}
</script>