<template>
  <div class="cart-contain container-fluid"
    :class="width != 'sm' ? ' mx-9' : ['mx-1', 'mb-8']"
  >
    <div class="row mt-5"
      v-if='shipMethods.length > 0'
    >
      <div class="col-12 col-lg-8 mb-3" >
        <div class="card-sec row mx-2 "  :class="width == 'sm' ? 'p-1': ''">
            <div class="col-12" >
              <div class="pro-header mb-4 required" :class="t_ar">
                {{$t('chooseShipping')}}
              </div>
              <div class="col-12" :class="t_ar">
                <div class="form-group" v-for='(method) in shipMethods' :key='method.id'>
                  <input 
                    required
                    type='radio'
                    :id='method.id'
                    :value='method'
                    v-model='shipping'
                  />
                  <label :for='method.id' class="opt-label mx-2">{{method.name}}</label>
                  <div class="px-1 my-2">
                    <div class="opt-label">- {{$t('price')}} <span :class="f_ar_reverse">€ {{method.delivery_price}}</span></div>
                    <div class="opt-label">- {{$t('orderTime')}} <span :class="f_ar_reverse"> {{method.delivery_time || 0 }} </span></div>
                  </div>
                </div>
              </div>
            </div>

          
           <div class="col-12" :class="t_ar">
              <div class="form-group my-4" >
                <div class="pro-header mb-4 ">
                  <span v-if="shipping.id !== 1">{{$t('enterLocation')}}</span>
                  <button  class="btn-def required" :class="[f_ar_reverse, !address.added ? 'missing-data' : '' ]" @click='openModal'>
                    {{shipping.id !== 1 ? 'Change Address' : 'Picker Details'}}
                  </button>
                </div>
                <div class="mb-4 " :class="width != 'sm' ? 'd-flex justify-content-around' : ''" v-if='address.added && shipping.id !== 1'>
                  <div class="data-card opt-label" >
                    <div class="data-label">{{$t('col_street')}}</div>
                    {{address.street}}
                  </div>
                  <div class="data-card opt-label">
                    <div class="data-label">{{$t('col_area')}}</div>
                    {{address.state}}
                  </div>
                  <div class="data-card opt-label">
                    <div class="data-label">{{$t('col_houseNumber')}}</div>
                    {{address.houseNo}}
                  </div>
                  <div class="data-card opt-label">
                    <div class="data-label">{{$t('col_postalcode')}}</div>
                    {{address.postalCode}}
                  </div>
                </div>
                <div class=" mb-4" v-else-if='address.added && shipping.id == 1'>
                  <div class="data-card opt-label">
                    <div class="data-label">{{$t('col_name')}}</div>
                    {{address.name}}
                  </div>
                  <div class="data-card opt-label">
                    <div class="data-label">{{$t('col_phone')}}</div>
                    {{address.phone}}
                  </div>              
                </div>
                <div v-else-if="shipping.id !== 1" class="opt-label px-1">
                  {{$t('addAddress')}} !!!
                </div>
              </div>
           </div>
            
            <div class="col-12" v-if="shipping.id !== 1" :class="t_ar">
              <div class="pro-header my-4 required">
                {{$t('choosePayment')}}
              </div>
              <div class="">
                <div class="form-group " v-for='method in payMethods' :key='method.id'>
                  <input 
                    type='radio'
                    :id='method'
                    :value='method'
                    v-model='payment'
                  />
                  <label :for='method' class="opt-label mx-2">{{payText(method)}}</label>
                  <span class="list-sub px-4 text-error" v-if='method == "paypal" && payment == "paypal" && credintials_missing == true'>{{$t('NotAvailable')}}</span>
                </div>
              </div>
            </div>

          <div class="col-12 col-lg-6 form-group my-4" :class="t_ar">
              <label class="pro-header mb-2">{{$t('CouponCode')}}</label>
              <input 
                :readonly='loading'
                @change="validateUserCoupon"
                type='text'
                v-model='coupon'
                class="text-input-checkout"
                :class="couponValide == false ? 'missing-data' : ''"
                :placeholder="$t('CouponCode')"
              />
              <span class="redeem-text mouse" :class="[
                            couponValide === false ? 'text-error': '', 
                            $i18n.locale == 'ar' ? 'redeem-text-ar': '',
                            couponValide == true ? 'text-success': '']">
                {{ redeemText() }}
              </span>
          </div>
          <div class="col-12 form-group my-4" :class="t_ar">
              <label class="pro-header mb-2">{{$t('specialRequest')}}</label>
              <input 
                type='text'
                class="text-input-checkout"
                v-model='comment'
                :placeholder="$t('specialRequest')"
              />
          </div>

          <div class="col-12 mt-3" v-if="width != 'sm'" >
            <div class="divider" ></div>
            <button 
              class="btn-pri-lg btn-block" 
              :disabled='loading' 
              :class="[t_ar, ( loading ? 'btn-secondary' : '' ) ]" 
              @click="placeOrder"
            > 
              {{$t('placeOrder')}}
              <i class="fa" v-if='!loading' :class="$i18n.locale == 'ar' ? ['fa-chevron-left', 'f-left'] : ['fa-chevron-right', 'f-right']"></i>
            </button>
          </div>
        </div>    
        
      </div> 
      <div 
        class="col-12 col-lg-4" 
        :class="width == 'sm' ? 'mt-3' : ''"  
      >
        <div class="card-sec row mx-2" :class="width == 'sm' ? 'p-2' : ''">
          <div class="col-12" :class="t_ar">
             <div class="pro-header mb-4">
              {{$t('summary')}}
             </div>
             <div class="opt-label">{{$t('TotalWithoutTax')}} <span :class="f_ar_reverse"> {{(subTotalWithNoTax).toFixed(2)}} €</span></div>
             <div class="opt-label">{{$t('VAT')}} <span :class="f_ar_reverse">{{(VAT).toFixed(2)}} €</span></div>
             <div class="opt-label">{{$t('subTotal')}} <span :class="f_ar_reverse">{{(subTotal).toFixed(2)}} €</span></div>
             <div class="opt-label mt-3">{{$t('delivery')}} <span :class="f_ar_reverse">{{shipping.delivery_price || 0}} €</span></div>
             <div class="opt-label">{{$t('CouponValue')}} <span :class="f_ar_reverse">{{(couponValue).toFixed(2) || 0}} €</span></div>
             
             <div class="opt-name text-center total-card mt-4">{{$t('total')}} {{(total)}} €</div>
          </div>
         <div class=" col-12 mt-5" v-if="width == 'sm'">
            <button 
              class="btn-pri-lg btn-block" 
              :disabled='loading' 
              :class="[t_ar, ( loading ? 'btn-secondary' : '' ) ]" 
              @click="placeOrder"
            > 
              {{$t('placeOrder')}}
              <i class="fa" v-if='!loading' :class="$i18n.locale == 'ar' ? ['fa-chevron-left', 'f-left'] : ['fa-chevron-right', 'f-right']"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="modal fade"  id='checoutModal'>
        <div class="mx-2 modal-dialog" :class='width == "sm" ? "w-100" : ["modal-lg", "mx-auto"]'>
          <div class="modal-content filter-contain">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body ">
              <div class="container-fluid">
                <AddressForm :addAddress='addAddress' :ship='shipping' :focus='focus'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoPlaces v-else/>  
     
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AddressForm from '@/components/forms/AddressForm';
import NoPlaces from '@/components/misc/NoPlaces';
import cookies from 'vue-cookies';
import md5 from 'md5';

export default {
  components: { 
    AddressForm,
    NoPlaces
  },
  data() {
    return {
      address: {},
      payment: '',
      shipping: {},
      subTotal: 0,
      subTotalWithNoTax: 0,
      availableCodes: [],
      coupon: '',
      coupon_code: '',
      couponValide: '',
      couponValue: 0,
      comment: '',
      focus: false,
      status: 'Pending',
      loaded: false,
      showPaypal: false,
      returnTotal: 0,
      updateSecret: '',
      client_id: null,
      client_secret: null,
      payment_id: '',
      fees: 0,
      transaction_id: '',
      order_id: '',
      payer_id: '',
      credintials_missing: false,
    }
  },
  watch: {
    shipping: function(newShip, oldShip) {
      if(oldShip.id == 1 && oldShip !== newShip) {
        this.address.added = false
      }

      this.payment = this.payMethods && newShip && newShip.id > 1 ? this.payMethods[0] : '';
      this.$forceUpdate();
    },
    shipMethods: function(data, old)
    {
        if(data !== old && data.length > 0)
        {
            this.shipping = data[0];
            this.$forceUpdate();
        }
    },
    // payment: function(newPay, oldPay) {
    //   if(newPay == 'paypal' && newPay != oldPay) {
    //     this.showPaypal = true;
    //   }else{
    //     this.showPaypal = false
    //   }
    // },
    client_id: function(newClient, lodClient) {
      // this.loadPaypalScript();
      // console.log(this.client_id)
    }
  },
  mounted() {
    let products = this.cartItems.map(el => {
      if (el.options != undefined) {
        return {
          product_id: el.product_id, 
          options: Object.values(el.options).map(opt => opt.id)
        }
      }else {
        return {
          product_id: el.product_id, 
          options: []
        }
      }
    });
    let quantity = this.cartItems.map(el => parseInt(el.quantity));
    let price = this.cartItems.map((el, index) => {
      if (el.options != undefined) {
        let total = 0;
        for(const [key, value] of Object.entries(el.options)) {
          total += parseInt(value.price) ;
        }
        this.subTotal += total * quantity[index];
        return {
          product_price: total,
          options_price: Object.values(el.options).map(opt => opt.price)
        }
      }else {
        this.subTotal += parseFloat(el.price) * quantity[index];
        return {
          product_price: el.price,
          options_price: el.price
        }
      }
    });
    let price_no_tax = this.cartItems.map((el, index) => {
      if (el.options != undefined) {
        let total = 0;
        for(const [key, value] of Object.entries(el.options)) {
          total += parseInt(value.price) ;
        }
        if(el.tax != 0) {
          total = total / ((el.tax / 100) +1);
        }
        this.subTotalWithNoTax += total * quantity[index];
      }else {
        let elPrice = el.price;
        if(el.tax != 0) {
          elPrice = el.price / ((el.tax / 100) +1);
        }
        this.subTotalWithNoTax += parseFloat(elPrice) * quantity[index];
      }
      
    }) 
    this.addToCart({
      products,
      quantity,
      price,
      lang: this.$i18n.locale,
    }).then(res => {
      if(this.postCodes.length > 0) {
        this.postCodes.forEach(el => {
          for(let code = el.from ; code <= el.to ; code++) {
            this.availableCodes.push(code);
          }
        }) 
      }
    })
  },
  methods: {
    ...mapActions(['addToCart', 'checkOutOrder', 'getAllCartItems', 'checkPhone', 'createAddress', 'updateAddress', 
                    'getToken', 'createOrder', 'captureOrder', 'approveOrder', 'updatePayment',
                    'createPayment', 'executePayment', 'executeRequest', 'validateCoupon']),
    ...mapMutations(['showMsg', 'destroyVCart']),
    payText(text) {
      if (text == 'cod') {
        return 'Cash On Delivery'
      } else if (text == 'pod') {
        return 'Payment On Delivery'
      }else if (text == 'paypal') {
        return 'Paypal'
      } 
    },    
    openModal() {
      $('#checoutModal').modal('show');
    },
    addAddress(address) {
      if(this.availableCodes.length > 0 && address.postalCode && this.shipping.id != 1) {
        if(this.availableCodes.find(el => el == address.postalCode)) {
          this.phoneValidate(address);
        }else {
            this.showMsg({
              text: this.$t('deliveryError', {postalCode: address.postalCode}),
              type: 'error',
              clsoe: true,
              condition: 2,
            })
          }
        }else {
          this.phoneValidate(address);
      } 
    },
    placeOrder() {
      if(this.shipping.id == 1) {
        this.payment = this.payMethods[0]
      }
      if (Object.entries(this.shipping).length > 0 && this.payment != '' && Object.entries(this.address).length > 0 && this.address.added == true) {
        this.checkOutOrder({
          address: this.address.street + ',' + this.address.state,
          address_id: cookies.get('addressId'),
          total: this.total,
          tax: this.VAT,
          payment: this.payment,
          shipping: this.shipping.id,
          deliveryCost: this.shipping.delivery_price,
          name: this.address.name,
          phone: this.address.phone,
          postal_code: this.address.postalCode,
          street: this.address.street,
          houseNo: this.address.houseNo,
          coupon_code: this.coupon_code,
          comment: this.comment,
        }).then( res => {
          let data = res.data.data;
          if(data.business.paypal_client_id != null && data.business.paypal_secret != null){
            this.client_id = data.business.paypal_client_id;
            this.client_secret = data.business.paypal_secret;
          }else {
            this.credintials_missing = true;
          }
            this.order_id = data.id;
            this.returnTotal = data.amount;
            this.payment_id = data.payment_id;
            this.updateSecret = this.makeSecret(data.created_at,data.id,data.payment_id,data.business.id);
            this.destroyVCart();
            this.getAllCartItems();
            this.$store.state.loading = true;
          if(this.payment == 'paypal'){ 
            if(this.credintials_missing == false) {
              this.showMsg({
                text: this.$t('orderSuccessfulRedirect'),
                type: 'success'
              });
              setTimeout(() => {
                this.$router.push({
                name: 'Payment',
                params: {
                  client_id: this.client_id,
                  client_secret: this.client_secret,
                  updateSecret: this.updateSecret,
                  order_id: this.order_id,
                  payment_id: this.payment_id,
                  total: this.total,

                  subTotalWithNoTax: this.subTotalWithNoTax,
                  VAT: this.VAT,
                  subTotal: this.subTotal,
                  delivery_price: this.shipping.delivery_price,
                  couponValue: this.couponValue
                }
              });
              }, 2000)
            }else{
              this.showMsg({
                text: this.$t('PaypalNotAvaialble'),
                type: 'error'
              });
            }
          }else{
            this.showMsg({
              text: this.$t('orderSuccessful'),
              type: 'success'
            });
            setTimeout(() => {
              this.$router.push({
                name: 'MyOrders',
                params: {
                  id: cookies.get('hold')
                }
              });
            }, 3000)
          }
        })

      }else {
        this.showMsg({
          text: this.$t('methodError'),
          type: 'error'
        });
        throw new Error(this.$t('methodError'))
      }
    },
    phoneValidate(address){
      // this.checkPhone(address.phone)
      //   .then(res => {
          this.focus = false;
          this.address = address;
          this.address.added = true;
          $('.modal').modal('hide');
          if(cookies.isKey('addressId')) {
            this.updateAddress({
              address: address.street + ',' + address.state,
              address_id: cookies.get('addressId'),
              postal_code: address.postalCode,
              street_name: address.street,
              apartment_no: address.houseNo,
              phone: address.phone
            })
          }else {
            this.createAddress({
              address: address.street + ',' + address.state,
              postal_code: address.postalCode,
              street_name: address.street,
              apartment_no: address.houseNo,
              phone: address.phone
            })
          }
        // }).catch(err => {
        //   this.showMsg({
        //     text: this.$t('notVaildNumber'),
        //     type: 'error'
        //   });
        //   this.focus = true;
        // })

    },
    
    makeSecret(created, id, pay, bus) {
      return md5(`${created}|${id}|${pay}|${bus}`);
    },
    validateUserCoupon() {
      // console.log(cookies.get('BusId'), this.coupon, 'heeerere')
      this.validateCoupon({
        business_id: cookies.get('BusId'),
        coupon_code: this.coupon,
      }).then(res => {
        let data = res.data.data;
        this.coupon_code = this.coupon;
        this.couponValide = true;
        if(data.type == 'fixed') {
          this.couponValue = data.value;
        }else if(data.type == 'percentage') {
          this.couponValue =  (parseFloat(this.subTotal) * data.value/100)
        }
      }).catch(err => {
        this.couponValue = 0;
        this.couponValide = false;

        this.showMsg({
          type: 'error',
          text: this.$t('CouponError')
        })
      })
    },
    redeemText() {
      if(this.couponValide == true) {
        return 'Redeemed'
      }else if(this.couponValide == '')  {
        return 'Redeem'
      }else if(this.couponValide === false) {
        return 'Not Valide'
      }
    }
  },
  computed: {
    ...mapGetters(['cartItems', 'shipMethods', 'payMethods', 'width', 'postCodes', 'loading']),
    total() {
      return (this.subTotal + (this.shipping.delivery_price || 0) - this.couponValue).toFixed(2) < 0 ? 0 : (this.subTotal + (this.shipping.delivery_price || 0) - this.couponValue).toFixed(2) ;
    },
    VAT() {
      return this.subTotal - this.subTotalWithNoTax;
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  }
}
</script>

<style scoped>
</style>