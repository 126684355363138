<template>
  <div class="container-fluid">
    <div 
      class="row"
      :dir="locale == 'ar'? 'rtl': ''"
    >
      <!-- Start the router -->
      <router-view class="" />
    </div>
  </div>
</template>

<script>
import { animations } from '../Helpers/animations';

export default {
  mixins: [animations],
  mounted() {
    this.$loadScript('../../../js/bootstrap.bundle.js');
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  }
}
</script>


<style scoped>
.row-layout{
  margin-top: 150px;
  height: auto;
}
</style>