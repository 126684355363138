import { lang } from "moment";
import request from "./httpService"
export default {
    locale: 'de',
    api: function(api, params, method)
    {
        if(!params) params = {};      
        params.lang = this.locale;  
        return request[method || 'post'](api, params);
    }
};