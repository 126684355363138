<template>
  <div 
    class="cart-contain container-fluid"
    :class="width == 'sm' ? 'mx-1' : 'mx-9'"
  >
    <div 
      class="cart-header row"
    >
      <div class="col-12">
        <div class="row">
          <div class="col-12 mb-5 mt-3">
            <div :class="f_ar">
              <i class="fa fa-credit-card-alt mx-1"></i>
              {{$t('myOrders')}} ({{myOrders ? myOrders.length : 0}})
            </div>
          </div>
        </div>
        <div class="row" v-if=" myOrders.length > 0 ">
            <div class="col-12 col-lg-6" v-for="order in renderOrders" :key='order.id'>
              <div 
                class="mainBody-card my-2"
                :class="isCanceled(order.id) ? 'mainBody-card-red' : ''"
              >
                <div 
                  class="opt-label"
                  :class="t_ar"
                >
                  {{$t('orderNumber')}}
                  <span 
                    class="opt-name"
                    :class="f_ar_reverse"
                  >{{order.id}}</span>
                </div>
                <div 
                  class="opt-label"
                  :class="t_ar"
                >
                  {{$t('orderDate')}}
                  <span 
                    class="opt-name"
                    :class="f_ar_reverse"
                  >{{order.date}}</span>
                </div>
                <div 
                  class="opt-label"
                  :class="t_ar"
                >
                  {{$t('orderTime')}}
                  <span class="opt-name"
                    :class="f_ar_reverse"
                  >{{order.time}}</span>
                </div>
                <div 
                  class="row d-flex justify-content-between px-2"
                  :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row'" 
                >
                  <button class="btn-sec mt-2" 
                  @click='openModal(order.id)'
                  > 
                    <i class="fa fa-info"></i>
                    {{$t('details')}} 
                  </button>
                  <span class="canceled" v-if='isCanceled(order.id)'>{{$t('canceled')}}</span>
                </div>
            </div>
          </div>
        </div>

        <div v-else class='row'>
          <NoPlaces class="col-12" :msg="$t('noOrders')"/>
        </div>
      </div>
    </div>

    <div class="modal fade pb-9" tabindex="-1"  id='orderModal'>
      <div class="mx-2 modal-dialog" :class='width == "sm" ? "w-100" : ["modal-xl", "mx-auto"]'>
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body ">
              <OrderDetails :item='order' :cancelOrder='cancelOrderClicked' :isCanceled='isCanceled' v-if="!modalLoading && order"/>
              <NoPlaces v-else/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import NoPlaces from '@/components/misc/NoPlaces';
import OrderDetails from '@/components/order/OrderDetails';

export default {
  data() {
    return {
      page: 1,
    }
  },
  components: {
    NoPlaces,
    OrderDetails
  },
  mounted() {
    this.scroll();
    this.getMyOrders()
      .then()
      .catch(err => console.log(err))
    this.getAllCanceled();
    if(this.$route.params.id && this.$route.params.id != false) {
      this.openModal(this.$route.params.id);
      this.$route.params.id = false;
    }
  },  
  computed: {
    ...mapGetters(['myOrders', 'order', 'width', 'canceled', 'loading', 'modalLoading']),
    renderOrders() {
      if ( this.page * 10 > this.myOrders.length) {
         return this.myOrders.slice(0, this.myOrders.length)
      }else return this.myOrders.slice(0, this.page * 10)
         
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  },
  methods: {
    ...mapActions(['getMyOrders', 'getOrder', 'cancelOrder', 'getAllCanceled']),
    ...mapMutations(['showMsg']),
    openModal(id) {
      $('#orderModal').modal('show');
      this.getOrder(id);
    },
    cancelOrderClicked(id) {
      this.showMsg({
        close: true,
        text: this.$t('sureCancel'),
        type: 'warning',
        condition: 6,
        orderId: id,
      })
      // this.cancelOrder(id)
      //   .then(res => {
      //     $('.modal').modal('hide');
      //     this.showMsg({
      //       text: 'Order Has been Canceled Successfully',
      //       type: 'success',
      //     });
      //     this.getAllCanceled();
      //   })
    },
    isCanceled(id) {
      return this.canceled.find(el => el == id) ? true : false;
    },
    nextPage() {
      this.page ++;
      console.log(this.page * 10, this.myOrders.length)
    },
    scroll () {
      window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.nextPage()
        }
      };
    }
  },
}
</script>