<template>
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="addAddress(address)" class="my-4 " >
        <div class="form-group row  ">
          <div class="col-12">
            <label class="input-label required" :class="f_ar">{{$t('col_name')}}</label>
            <input 
              type='text'
              :disabled="inProgress"
              class="text-input-bot"
              v-model='address.name'
              :placeholder="$t('col_name')"
              required
            />
          </div>
        </div>
        <div class="divider"></div>
        <div class="form-group row" >
          <div class="col-12">
            <label class="input-label required" :class="f_ar">
              {{$t('col_phone')}}
              <i class="fa fa-spin fa-spinner" v-if="FieldsStatus.phone === 'loading'"></i>
            </label>
            <!-- <select class="col-sm-2 select-input-bot"> 
              <option value='+49'>+49</option>
            </select> -->
            <input 
              @blur="validatePhone"
              required
              ref='phoneNumber'
              v-mask="phoneMask"
              :masked="true"
              type='text'
              :disabled="inProgress"
              class="text-input-bot"
              :class="{'form-control is-invalid': FieldsStatus.phone === false, 'form-control is-valid': FieldsStatus.phone === true}"
              v-model='address.phone'
              placeholder="49 000 0000000"
            />
            <small class="text-danger mt-2" v-if="FieldsStatus.phone === false">{{'notVaildNumber'|t}}</small>
          </div>
        </div>
        <div class="divider"></div>
        <div class="form-group row" v-if="ship.id != 1">
          <div class="col-12 col-md-7">
            <label class="input-label required" :class="f_ar">{{$t('col_street')}}</label>
            <input 
              required
               :disabled="inProgress"
              type='text'
              class="text-input-bot"
              v-model='address.street'
              :placeholder="$t('col_street')"
              
            />
          </div>
          <div class="col-12 col-md-5">
            <label class="input-label required" :class="f_ar">{{$t('col_houseNumber')}}</label>
            <input 
              required
               :disabled="inProgress"
              type='text'
              class="text-input-bot"
              v-model='address.houseNo'
              :placeholder="$t('col_houseNumber')"
            />
          </div>
        </div>
        <div class="divider"></div>
        <div class="form-group row" v-if="ship.id != 1">
          <div class="col-12 col-md-7">
            <label class="input-label required" :class="f_ar">{{$t('PlaceCity')}}</label>
            <input 
              required
               :disabled="inProgress"
              type='text'
              class="text-input-bot"
              v-model='address.state'
              :placeholder="$t('PlaceCity')"
            />
          </div>
          <div class="col-12 col-md-5">
            <label class="input-label required" :class="f_ar">{{$t('col_postalcode')}}</label>
            <input 
              required
               :disabled="inProgress"
              type='number'
              class="text-input-bot"
              v-model='address.postalCode'
              :placeholder="$t('col_postalcode')"
            />
          </div>
        </div>
        
        <div class="modal-footer">
          <button type='submit' :disabled='loading || inProgress' :class=" loading ? 'btn-secondary' : '' " class="btn-pri col-12 mt-8">
            <i class="fa fa-spin fa-spinner" v-if="loading || inProgress"></i>
            {{$t('save')}}
          </button> 
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
  props: {
      addAddress: {
        required: true,
        type: Function
      },
      ship: {
        required: true,
        type: Object
      }, 
      focus: Boolean,
    },
  data() {
    return {
      phoneMask : '49###############',
      address: {
        name: '',
        phone: '',
        street: '',
        houseNo: '',
        postalCode: '',
        state: '',
        number: '',
      },
      inProgress: false,
      FieldsStatus: {}
    }
  },
  watch: {
    focus: function(newF , oldF) {
      if (newF != oldF && newF == true) {
        this.$refs.phoneNumber.focus()
        console.log(this.$refs.phoneNumber)
      }
    }
  },
  methods: {
    ...mapActions(['checkPhone']),
    validatePhone()
    {      
        this.FieldsStatus.phone = 'loading';
        this.inProgress = true;
        this.$forceUpdate();        
        this.checkPhone(this.address.phone).then(res => {
            if(res.data.success)
            {                    
                this.inProgress = false;
                this.FieldsStatus.phone = true;
                this.$forceUpdate();
            }
            else 
            {
                this.makePhoneError();
            }
        }).catch(err => {
          this.makePhoneError();
        });
    },
    makePhoneError()
    {
        this.inProgress = false;
        this.FieldsStatus.phone = false;
        this.$forceUpdate();
        this.$nextTick(() => {
          $(this.$refs.phoneNumber).focus();
        });
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  }
}
</script>